<style scoped lang="scss">
@import "./style.scss";
</style>

<template src="./template.html"></template>

<script>
import EmptySection from "@/components/generals/EmptySection";
const NumAbbr = require("number-abbreviate");

export default {
  name: "WaiterHeader",
  components: { EmptySection },
  filters: {
    numAbbr: val => new NumAbbr().abbreviate(val)
  },
  props: {
    notifications: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const count = this.filterByReaded(this.notifications).length;
    return { dialog: false, count, fullscreen: false };
  },
  watch: {
    notifications: {
      deep: true,
      handler(data) {
        this.count = this.filterByReaded(data).length;
      }
    }
  },
  computed: {
    haveNotifications() {
      return this.notifications.length > 0;
    }
  },
  mounted() {
    this.fullscreen = window.matchMedia("(max-width: 500px)").matches;
  },
  methods: {
    redirectToProfile() {
      const { query = {}, hash = "" } = window.router.currentRoute;
      window.router.push({ name: "profile" });
      window.router.replace({ query, hash });
    },
    filterByReaded(notifications) {
      return notifications.filter(notification => notification.readed === false);
    },
    handleConfirmRequest(data) {
      this.$emit("confirmRequest", data.sender);
      const notification = this.notifications.find(item => item.id === data.id);
      notification.readed = true;
      this.count = this.filterByReaded(this.notifications).length;
    }
  }
};
</script>
